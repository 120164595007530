import React from "react"

import { Image } from "rebass"

export default ({ item }) => {
  console.log(item)
  const isVideo = !item.fixed
  const content = isVideo ? (
    <video
      playsInline
      controls
      style={{
        height: "100%",
        maxWidth: ["900px"],
        backgroundColor: "black",
      }}
    >
      <source src={item.file.url} type="video/mp4" />
      <p>
        {item?.description}
      </p>
      <p>
        Video could not be found.{" "}
        <a href="mailto:greg@plank.ai">Please let me know</a>
      </p>
    </video>
  ) : (
    <Image
      src={item.fixed.src}
      sx={{
        height: ["auto", item.fixed.height / 3],
        maxWidth: ["750px", item.fixed.width / 3],
        width: ["100%", item.fixed.width / 3],
        marginBottom: [32, 0],
      }}
      alt={item?.description}

    />
  )

  return content
}
