// core version + navigation, pagination modules:
import React from "react"
import Layout from "../components/Layout"
import ImageGallery from "../components/ImageGallery"
import { Flex, Box } from "rebass"
import Link from "../components/Link"

export default function Gallery({ pageContext }) {
  return (
    <Layout>
      <Flex mb={16} px="3">
        <Box mx="auto" />
        {/* <MediaQuery minDeviceWidth={768}>
          <a href="/">
            <CloseIcon fill="#303030" />
          </a>
        </MediaQuery> */}
      </Flex>
      <ImageGallery images={pageContext.node.content} />
      <Flex mb={32}>
        <Link to="/" variant="goBack">
          Go back
        </Link>
      </Flex>
    </Layout>

    // <Layout>
    //   <ImageGallery images={pageContext.node.content} />
    // </Layout>
  )
}
