import React from "react"

import { Box, Flex } from "rebass"

import ImageGalleryItem from "./ImageGalleryItem"

import SwiperCore, { Navigation, A11y } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "../app.scss"
import "swiper/swiper.scss"

import "../navigation.scss"

import MediaQuery from "react-responsive"

// install Swiper components
SwiperCore.use([Navigation, A11y])

export default ({ images }) => {
  if (!images) {
    return null
  }
  if (images?.length === 1) {
    return (
      <Flex justifyContent="center" mb={32}>
        <ImageGalleryItem item={images[0]} />
      </Flex>
    )
  }

  return (
    <Box>
      <MediaQuery minDeviceWidth={768}>
        <Box sx={{ position: "relative" }} mb={64}>
          <Swiper
            navigation
            loop
            centeredSlides={true}
            spaceBetween={30}
            slidesPerView="auto"
          >
            {images.map(item => (
              <SwiperSlide>
                <ImageGalleryItem {...{ item }} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={768}>
        {images.map(item => (
          <ImageGalleryItem {...{ item }} mb={12} />
        ))}
      </MediaQuery>
    </Box>
  )
}
